$brandDarkBlue: #012c59;
$brandBlue: #5197D9;
$black: #000000;

$theme-colors: (
  'darkblue': $brandDarkBlue,
  'blue': $brandBlue,
  'black': $black
);

$enable-responsive-font-sizes: true;

@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body { font-family: 'Roboto' }

.text-shadow { text-shadow: 0 0 15px #000; }
